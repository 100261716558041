<template>
	<div class="debug" v-if="actualDevice">
		[{{ actualDevice.label }} {{ actualDeviceChangeCount }}]
		Click:{{ nextCameraBtnClickCount }} {{ windowInnerWidth }}x{{ windowInnerHeight }}
	</div>
	<div id="qr-code-scanner" ref="scanner"></div>
	<button
		class="turn-camera-btn"
		:style="{ backgroundImage: `url(${require('@/assets/rotate.png')})` }"
		@click="nextCamera"
	></button>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import Cookies from 'js-cookie';
export default {
	name: 'qrcode-scanner',
	emits: ['decode', 'loaded', 'stopped'],
	props: ['isHorizontal'],
	data() {
		return {
			devices: [],
			actualDeviceIndex: -1,
			actualDeviceChangeCount: 0,
			nextCameraBtnClickCount: 0,
			windowInnerWidth: window.innerWidth,
			windowInnerHeight: window.innerHeight,
		};
	},
	computed: {
		actualDevice() {
			return this.devices[this.actualDeviceIndex];
		},
	},
	async mounted() {
		try {
			const devices = await Html5Qrcode.getCameras();
			console.debug(`devices`, devices);
			if (!devices) return;
			if (!devices.length) return;
			if (process.env.NODE_ENV !== 'development') {
				this.devices = devices;
			}

			if (process.env.NODE_ENV === 'development') {
				this.devices = [{ ...devices[0] }, { ...devices[0] }];
				this.devices[1].label = 'USB Video Device (TEST)';
			}

			const actualDeviceId = Cookies.get('actualDeviceId') || '';
			const actualDeviceIndex = this.devices.findIndex(
				(device) => device.id === actualDeviceId
			);
			this.actualDeviceIndex =
				actualDeviceIndex >= 0 ? actualDeviceIndex : 0;

		} catch(err) {
			console.debug(`err`, err);

		}

		window.addEventListener('resize', () => {
			this.windowInnerWidth = window.innerWidth;
			this.windowInnerHeight = window.innerHeight;
		});
	},
	methods: {
		startScanning() {
			this.$emit('loaded');
			this.html5QrCode = new Html5Qrcode(this.$refs.scanner.id);
			this.html5QrCode
				.start(
					this.actualDevice.id,
					{
						fps: 10, // Optional, frame per seconds for qr code scanning
						rememberLastUsedCamera: true,
					},
					(decodedText, decodedResult) => {
						this.onScanSuccess(decodedResult);
					},
					(errorMessage) => {
						errorMessage;
					}
				)
				.catch((err) => {
					console.debug(`err`, err);
				});
		},
		restart() {
			this.actualDeviceId = this.actualDevice.id;
			if (!this.html5QrCode) {
				this.startScanning();
				return;
			}
			this.html5QrCode
				.stop()
				.then(() => {
					this.$emit('stopped');
					this.startScanning();
				})
				.catch((err) => {
					// Stop failed, handle it.
					console.debug(`err`, err);
				});
		},
		onScanSuccess(decodedResult) {
			this.$emit('decode', decodedResult);
		},
		nextCamera() {
			this.nextCameraBtnClickCount++;
			this.actualDeviceIndex =
				this.actualDeviceIndex < this.devices.length - 1
					? this.actualDeviceIndex + 1
					: 0;
		},
	},
	watch: {
		actualDeviceIndex(newVal) {
			this.actualDeviceChangeCount++;
			Cookies.set('actualDeviceId', this.devices[newVal].id);
			this.restart();
		},
		isHorizontal() {
			this.restart();
		},
	},
};
</script>

<style lang="scss" scoped>
.debug {
	position: fixed;
	top: 0;
	left: 0;
	color: red;
	font-weight: bold;
}
.turn-camera-btn {
	width: 55px;
	height: 55px;
	display: block;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.5);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	bottom: 20px;
	right: 20px;
	border: none;
	z-index: 10000;
}
</style>
