<template>
	<div class="popup-message" v-show="isActive">
		<div class="popup-message__text">
			<div class="popup-message__close-btn" @click="isActive = false">×</div>
			{{ text }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'popup-message',
	props: ['text', 'timeout'],
	data() {
		return {
			isActive: !false,
			msRemain: 2000,
			timeoutId: null,
		}
	},
	watch: {
		text() {
			this.isActive = true;
			if (this.timeoutId) clearTimeout(this.timeoutId);
			this.timeoutId = setTimeout(() => {
				this.isActive = false;
			}, this.timeout || 2000);
		}
	}
}
</script>

<style lang="scss" scoped>
.popup-message {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
	&__text {
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		color: #000;
		margin: 0 20px;
		padding: 30px 30px 10px 30px;
		font-size: 1.5em;
	}
	&__close-btn {
		position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
	}
}
</style>