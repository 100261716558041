<template>
  <router-view/>
</template>

<script>
import '@/assets/style.css';
export default {
  data() {
    return {
      logo: require('@/assets/logo.png'),
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
