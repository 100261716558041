<template>
	<div class="spinner-rolling">
		<div class="spinner-rolling__inner">
			<div></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'app-spinner',
};
</script>

<style>
@keyframes spinner-rolling__inner {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.spinner-rolling__inner div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid #e4007d;
  border-top-color: transparent;
  border-radius: 50%;
}
.spinner-rolling__inner div {
  animation: spinner-rolling__inner 1s linear infinite;
  top: 50px;
  left: 50px
}
.spinner-rolling {
  width: 75px;
  height: 75px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
	border-radius: 10px;
	position: absolute;
	left: calc(50% - 75px / 2);
	top: calc(50% - 75px / 2);
}
.spinner-rolling__inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.75);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.spinner-rolling__inner div { box-sizing: content-box; }
</style>
