<template>
	<div class="wrapper">
		<div class="logo" :style="{ backgroundImage: `url(${logo})` }"></div>
		<router-link class="start-btn" to="/start">Начать</router-link>
	</div>
</template>

<script>
export default {
	name: 'app-start',
	data() {
		return {
			logo: require('@/assets/logo.png'),
		};
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #111;
	display: flex;
	align-items: center;
	justify-content: center;
}
.start-btn {
	font-size: 24px;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	padding: 10px 30px;
	background-color: #ff0080;
	border-radius: 10px;
	font-weight: bold;
}
.logo {
	position: fixed;
	top: 1rem;
	height: 10vh;
	width: 100%;
	max-width: 200px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;
	margin: 0 auto;
	transform: translateX(5%);
	margin-bottom: 1rem;
}
</style>
