import axios from 'axios';
export default class {
	constructor() {
		this._baseUrl = process.env.VUE_APP_AJAX_BASE_URL;
	}
	async getProductInfo(barcode, storeCode) {
		const url = `${this._baseUrl}/getProductInfo.php?barcode=${barcode}&storeCode=${storeCode}`;
		const response = await axios.get(url);
		return response;
	}
	async getStores() {
		const url = `${this._baseUrl}/getStores.php`;
		const response = await axios.get(url);
		return await response;
	}
}