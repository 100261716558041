<template>
	<div
		class="scanner"
		:class="{ scanner_horisontal: isHorizontal }"
		ref="wrapper"
	>
		<div class="row-top">
			<div class="logo">
				<img :src="logo" alt="MonAmie" />
			</div>
			<div class="store-name">
				{{ storeName }}
				<div class="store-name__note">
					Другой магазин? Наведите на QR-код магазина
				</div>
			</div>
			<div class="title">
				{{
					!storeName
						? `Наведите на QR-код магазина`
						: `Наведите на штрих-код товара`
				}}
			</div>
		</div>
		<div class="row-center">
			<app-spinner v-if="isLoading"></app-spinner>
			<qrcode-scanner
				@loaded="onLoaded"
				@decode="onDecode"
				@stopped="onStopped"
				:isHorizontal="isHorizontal"
			></qrcode-scanner>
		</div>
		<div class="overlay-1"></div>
		<div class="overlay-2"></div>
		<div class="row-bottom">
			<div class="scanner__result">
				<div class="scanner__result-value" v-show="productPrice || productPriceD">
					<span class="old-price">{{ formattedPrice }} ₸</span> <span>{{ formattedPriceD }} ₸</span>
				</div>
				<div class="scanner__result-value" v-show="errorMessage">
					{{ errorMessage }}
				</div>
				<div class="scanner__result-description" v-show="productName">
					{{ productName }}
					<span class="scanner__result-barcode">{{ barcode }}</span>
					<br /><span class="scanner__result-amount"
						>Остаток: {{ productAmount }}</span
					>
				</div>
			</div>
		</div>
		<popup-message :text="popupMessage" v-show="popupMessage"></popup-message>
	</div>
</template>

<script>
import AppSpinner from '@/components/AppSpinner';
import PopupMessage from '@/components/PopupMessage';
import QrcodeScanner from '@/components/QrcodeScanner';
import Service from '@/service';
import Cookies from 'js-cookie';

const allowedBarcodes = [ 'EAN_13', 'EAN_8', 'UPC_A', 'UPC_E', 'UPC_EAN_EXTENSION' ];

export default {
	name: 'camera-view',
	data() {
		const storeCode = Cookies.get('storeCode');
		const storeName = Cookies.get('storeName');
		const isSettedCookies = storeCode && storeName;
		return {
			isLoading: true,
			stores: [],
			barcode: '',
			storeCode: isSettedCookies ? storeCode : '',
			storeName: isSettedCookies ? storeName : '',
			productPrice: 0,
			productPriceD: 0,
			productAmount: 0,
			productName: '',
			errorMessage: 'Разрешите использование камеры',
			popupMessage: '',
			logo: require('@/assets/logo.png'),
			isHorizontal: window.innerWidth > window.innerHeight,
		};
	},
	components: { AppSpinner, QrcodeScanner, PopupMessage },
	computed: {
		formattedPrice() {
			return new Intl.NumberFormat('ru-RU').format(this.productPrice);
		},
		formattedPriceD() {
			return new Intl.NumberFormat('ru-RU').format(this.productPriceD);
		},
	},
	methods: {
		onDecode(e) {
			const resultFormat = e.result.format.formatName;
			const result = e.result.text;
			if (result && result === this.result) return;
			this.result = result;

			// проверка на валидный штрихкод
			if (allowedBarcodes.includes(resultFormat)) {
				this.barcode = result;
				this.updateProduct();
				return;
			}

			// проверка на валидный код магазина
			const storeMatch = `${result}`.match(/qr\.monamie.kz\/store-(.+)/);
			if (storeMatch) {
				this.storeCode = storeMatch[1];
				this.updateStore();
				return;
			}

			this.errorMessage = 'Формат кода не поддерживается';
		},
		reset() {
			this.productPrice = 0;
			this.productPriceD = 0;
			this.productName = '';
			this.errorMessage = '';
		},
		onLoaded() {
			if (this.barcode && this.storeCode) this.updateProduct();
			this.isLoading = false;
			this.reset();
		},
		onStopped() {
			this.isLoading = true;
		},
		async updateProduct() {
			if (!this.barcode) return;
			this.reset();
			const service = new Service();
			this.isLoading = true;
			let response = null;
			try {
				response = await service.getProductInfo(this.barcode, this.storeCode);
			} catch (e) {
				const message = e.response.data['Ошибка'];
				this.errorMessage = message || 'Что-то пошло не так...';
			}
			this.isLoading = false;
			const data = response.data;
			if (!data) return;
			if (data['Цена']) {
				const discount = +data['СуммаСкидки'] || 0;
				this.productPrice = +data['Цена'];
				this.productPriceD = +data['Цена'] - discount;
			}
			if (data['Наименование']) this.productName = data['Наименование'];
			if (data['КоличествоОстаток']) this.productAmount = +data['КоличествоОстаток'];
			this.$router.push({
				name: `home-with-store-and-barcode`,
				params: { storeCode: this.storeCode, barcode: this.barcode },
			});
		},
		async getStores() {
			const service = new Service();
			this.isLoading = true;
			let response = null;
			try {
				response = await service.getStores();
			} catch (e) {
				const message = e.response.data['Ошибка'];
				this.errorMessage = message || 'Что-то пошло не так...';
			}
			this.isLoading = false;
			const data = response.data;
			if (!data) return;
			if (data['Ошибка']) this.errorMessage = data['Ошибка'];
			if (Array.isArray(data)) {
				this.stores = data;
				this.errorMessage = '';
				this.updateStore();
			}
		},
		setCookies() {
			Cookies.set('storeCode', this.storeCode, { expires: 14 });
			Cookies.set('storeName', this.storeName, { expires: 14 });
		},
		updateStore() {
			this.reset();
			if (!this.stores.length) return;
			if (!this.storeCode) this.storeCode = this.stores[0]['Код'];
			const searchResult = this.stores.find(
				(store) => store['Код'] === this.storeCode
			);
			if (searchResult) this.storeName = searchResult['Наименование'];
			if (!searchResult) this.storeCode = this.stores[0]['Код'];
			this.setCookies();
			this.$router.push({
				name: `home-with-store`,
				params: { storeCode: this.storeCode },
			});
			this.popupMessage = this.storeName;
		},
		onResize() {
			this.isHorizontal = window.innerWidth > window.innerHeight;
			setTimeout(() => {
				// Исправление 100vh для мобильных браузеров.
				// Взято отсюда: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			}, 150);
		},
	},
	created() {
		this.getStores();
		if (this.$route.params.storeCode)
			this.storeCode = this.$route.params.storeCode;
		if (!this.$route.params.storeCode && this.storeCode)
			this.$router.push({
				name: `home-with-store`,
				params: { storeCode: this.storeCode },
			});
		if (this.storeCode && this.$route.params.barcode) {
			this.barcode = this.$route.params.barcode;
		}
	},
	mounted() {
		// Исправление 100vh для мобильных браузеров.
		// Взято отсюда: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		window.addEventListener('resize', () => {
			this.onResize();
		});
	},
};
</script>

<style lang="scss">
$overlay-color: rgba(0, 0, 0, 0.8);
$scanner-size: 280px;
.scanner {
	display: grid;
	grid-template-areas:
		"info info info"
		"overlay-1 scanner overlay-2"
		"result result result";
	grid-template-rows: 1fr $scanner-size 1fr;
	grid-template-columns: 1fr $scanner-size 1fr;
	// Исправление 100vh для мобильных браузеров.
	// Взято отсюда: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	// ---------------------------------------------------------------------------
	width: 100%;
	position: relative;
	.btn-cancel {
		color: #fff;
		text-align: center;
		text-decoration: underline;
		position: absolute;
		bottom: 1em;
		width: 100%;
	}
	.btn-run {
		position: absolute;
		bottom: 2rem;
		align-self: center;
	}
}
.row-top,
.row-bottom,
.overlay-1,
.overlay-2,
.row-top {
	background-color: $overlay-color;
}
.row-top {
	grid-area: info;
}
.row-center {
	grid-area: overlay-1 / overlay-1 / overlay-2 / overlay-2;
}
.row-bottom {
	grid-area: result;
	z-index: 9999;
}
.overlay-1 {
	grid-area: overlay-1;
}
.overlay-2 {
	grid-area: overlay-2;
}
.scanner_horisontal {
	grid-template-areas: 
		"info overlay-1 result"
		"info scanner result"
		"info overlay-2 result";
	height: 100vh;
	.row-top {
		grid-area: info;
	}
	.row-center {
		grid-area: info / info / result / result;
	}
	.row-bottom {
		grid-area: result;
	}
}


.row-top,
.row-bottom {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
}

.row-top,
.row-bottom {
	padding: 1rem;
}
.row-top,
.row-bottom,
.row-center {
	display: flex;
	position: relative;
}
.row-bottom {
	overflow-y: auto;
}

.logo {
	width: 100%;
	max-width: 150px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 1;
	margin: 0 auto 1.2rem auto;
	transform: translateX(5%);
}
.logo img {
	width: 100%;
	height: auto;
}
.column-side {
	flex-grow: 1;
}
.column-center {
	width: 280px;
	position: relative;
	&::before {
		display: block;
		width: 0;
		padding-bottom: 100%;
		content: '';
	}
}
.row-top,
.row-bottom,
.column-side {
	background-color: $overlay-color;
}
.store-name {
	flex-grow: 1;
	color: #fff;
	font-size: 1.2rem;
	text-align: center;
	line-height: 1.2;
}
.store-name__note {
	font-size: 0.8rem;
	margin-top: 0.75em;
}
.title {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 1.3rem;
	font-weight: 400;
}
.camera {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

$loading-background: #fff;
$loading-icon-size: #36a862;
.loading-overlay {
	--loading-overlay-width: 80px;
	top: calc(50% - var(--loading-overlay-width) / 2);
	right: auto;
	bottom: auto;
	left: calc(50% - var(--loading-overlay-width) / 2);
	width: 80px;
	height: 80px;
	.loading-icon:after {
		border-left-color: #36a862;
		border-bottom-color: #36a862;
	}
	.loading-background {
		background-color: $loading-background;
		border-radius: 10px;
	}
}

.scanner-container {
	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	> div:first-of-type {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.laser {
		display: none;
		margin-left: 0;
		top: calc(50% - 50px);
		animation: scanning 2s infinite;
	}
}

.scanner__result {
	// position: absolute;
	color: #fff;
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
	height: 100%;
	&-value {
		margin-bottom: 1rem;
		font-size: 22px;
		background-color: rgba(0, 0, 0, 0.75);
		border-radius: 5px;
		padding: 5px 15px;
		word-break: break-word;
	}
	&-description {
		font-size: 16px;
		background-color: rgba(0, 0, 0, 0.75);
		border-radius: 5px;
		padding: 5px 15px;
	}
	&-barcode {
		color: #aaa;
	}
}

.scanner_invisible {
	opacity: 0;
}

@keyframes scanning {
	50% {
		transform: translateY(100px);
	}
}

.scanner-container .overlay-element {
	display: none;
}

.old-price {
	font-size: 0.8em;
	text-decoration: line-through;
}

.debug {
	position: fixed;
	bottom: 5px;
	left: 5px;
	z-index: 9999;
	color: #fff;
}
</style>
